<template>
  <div>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
        <c-table
          ref="mstTable"
          title="기계장비유형"
          tableId="mstTable"
          :columnSetting="false"
          :isFullScreen="false"
          :usePaging="false"
          :filtering="false"
          :columns="mstGrid.columns"
          :isExcelDown="false"
          :data="mstGrid.data"
          @rowClick="rowClick"
        >
          <!-- selection="multiple"
          rowKey="code" -->
          <!-- 버튼 영역 -->
          <template slot="table-button">
            <!-- <c-btn label="추가" icon="add" @btnClicked="addrowType" v-if="editable" />
            <c-btn label="저장" icon="save" @btnClicked="saveType" v-if="editable" />
            <c-btn label="삭제" icon="remove" @btnClicked="removeType" v-if="editable" /> -->
          </template>
          <template v-slot:customArea="{ props,col }">
            <template>
              <q-chip
                v-if="col.name==='openIns' && props.row.editFlag != 'C'"
                color="blue"
                :clickable="true"
                text-color="white">
                ▶
              </q-chip>
            </template>
          </template>
        </c-table>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-8 col-lg-8 col-xl-8">
        <c-table
          ref="grid"
          title="유형별 점검항목"
          tableId="table"
          :columns="grid.columns"
          :data="grid.data"
          selection="single"
          rowKey="hazardousMachineryTypeInsId"
        >
          <!-- 버튼 영역 -->
          <template slot="table-button">
            <q-btn-group outline >
              <c-btn label="동일구분 점검항목추가" icon="add" @btnClicked="addrowCopy" v-if="editable && selectedRows.code && selectedRows.editFlag != 'C'" />
              <c-btn label="추가" icon="add" @btnClicked="addrowIns" v-if="editable && selectedRows.code && selectedRows.editFlag != 'C'" />
              <c-btn label="저장" icon="save" @btnClicked="saveIns" v-if="editable && selectedRows.code && selectedRows.editFlag != 'C'" />
              <c-btn label="삭제" icon="remove" @btnClicked="removeRowIns" v-if="editable && selectedRows.code && selectedRows.editFlag != 'C'" />
            </q-btn-group>
          </template>
        </c-table>
      </div>
    </div>
  </div>
</template>

<script>
import { uid } from 'quasar';
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'project-manage',
  data() {
    return {
      mstGrid: {
        columns: [
          {
            name: 'codeName',
            field: 'codeName',
            label: '기계장비유형',
            align: 'left',
            // type: 'text',
            sortable: false,
          },
          // {
          //   name: 'sortOrder',
          //   field: 'sortOrder',
          //   label: '순번',
          //   style: 'width:70px',
          //   align: 'center',
          //   type: 'number',
          //   sortable: false,
          // },
          // {
          //   name: 'useFlag',
          //   field: 'useFlag',
          //   label: '사용여부',
          //   align: 'center',
          //   style: 'width:70px',
          //   type: 'check',
          //   true: 'Y',
          //   false: 'N',
          //   sortable: false,
          // },
          {
            name: 'openIns',
            field: 'openIns',
            label: '점검항목',
            align: 'center',
            style: 'width:80px',
            type: 'custom',
            sortable: false
          },
        ],
        data: [],
      },
      grid: {
        columns: [
          {
            name: 'typeName',
            field: 'typeName',
            label: '구분',
            align: 'left',
            style: 'width:250px',
            type: 'text',
            sortable: false,
          },
          {
            name: 'sortOrder',
            field: 'sortOrder',
            label: '순번',
            style: 'width:70px',
            align: 'center',
            type: 'number',
            sortable: false,
          },
          {
            name: 'inspectItem',
            field: 'inspectItem',
            label: '점검항목',
            align: 'left',
            type: 'textarea',
            sortable: true,
          },
        ],
        data: [],
      },
      editable: true,
      headerUrl: '',
      listUrl: '',
      selectedRows: {}
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.$comm.getComboItems('HHM_TYPE_CD').then(_result => {
        _result = this.$_.filter(_result, {useFlag : 'Y' });
        this.mstGrid.data = _result
      })
    },
    rowRemoveSelect() {
      var _table = document.getElementsByClassName('bg-light-blue-1');
      if (_table.length > 0) {
        for(let c = 0; c <= _table.length; c++) {
          _table[c].classList.remove('bg-light-blue-1');
        }
      }
    },
    rowClick(row) {
      this.selectedRows = row;
      this.$http.url = selectConfig.mdm.cmd.inspectitem.list.url;
      this.$http.type = 'GET';
      this.$http.param = {hhmTypeCd: this.selectedRows.code};
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    addrowIns() {
      let len = this.grid.data.length + 1;
      this.grid.data.push({
        editFlag: 'C',
        companyCd: this.$store.getters.user.companyCd,
        hazardousMachineryTypeInsId: uid(),  // 기계장비유형별 점검항목일련번호
        hhmTypeCd: this.selectedRows.code,  // 기계장비유형코드
        typeName: '',  // 구분
        inspectItem: '',  // 자율점검항목
        sortOrder: len,  // 순번
      });
    },
    saveIns() {
      let checkItem = ['typeName', 'inspectItem']
      let isConti = true;
      this.$_.forEach(this.grid.data, item => {
        this.$_.forEach(checkItem, check => {
          if (!item[check]) {
            isConti = false;
            return false;
          }
        })
      });
      if (!isConti) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '필수 입력값을 입력해 주세요. [구분, 자율점검항목]',
          type: 'warning', // success / info / warning / error
        });
      } else {
        let saveData = this.grid.data.filter( x => {
          return x.editFlag == 'C' || x.editFlag == 'U'
        });
        this.$_.forEach(saveData, item => {
          item.regUserId = this.$store.getters.user.userId;
          item.chgUserId = this.$store.getters.user.userId;
        });
        if(saveData.length > 0) {
          window.getApp.$emit('CONFIRM', {
            title: '확인',
            message: '저장하시겠습니까?',
            type: 'info',
            // 확인 callback 함수
            confirmCallback: () => {
              this.$http.url = transactionConfig.mdm.cmd.inspectitem.save.url;
              this.$http.type = 'POST';
              this.$http.param = saveData;
              this.$http.request(() => {
                this.rowClick(this.selectedRows);
                window.getApp.$emit('APP_REQUEST_SUCCESS');
              });
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('ALERT', {
            title: '안내 ', /* 안내 */
            message: '변경된 데이터가 없습니다.', /* 변경된 데이터가 없습니다. */
            type: 'info', // success / info / warning / error
          });
        }
      }
    },
    removeRowIns() {
      let selectData = this.$refs['grid'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '하나 이상 선택하세요.', // 하나 이상 선택하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '삭제하시겠습니까?',
          type: 'warning', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = transactionConfig.mdm.cmd.inspectitem.delete.url;
            this.$http.type = 'DELETE';
            this.$http.param = {
              data: Object.values(selectData)
            };
            this.$http.request(() => {
              // this.rowClick(this.selectedRows);
              window.getApp.$emit('APP_REQUEST_SUCCESS');
              
              this.$_.forEach(selectData, item => {
                this.grid.data = this.$_.reject(this.grid.data, item);
              })
              this.$refs['grid'].$refs['compo-table'].clearSelection();
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    addrowType() {
      let len = this.mstGrid.data.length + 1;
      this.mstGrid.data.push({
        editFlag: 'C',
        codeGrpCd: 'HHM_TYPE_CD',  // 코드그룹
        code: uid(),  // 코드
        codeName: '',  // 코드명
        useFlag: 'Y',  // 사용여부
        sortOrder: len,  //
      });
    },
    saveType() {
      let checkItem = ['codeName', 'sortOrder']
      let isConti = true;
      this.$_.forEach(this.mstGrid.data, item => {
        this.$_.forEach(checkItem, check => {
          if (!item[check]) {
            isConti = false;
            return false;
          }
        })
      });
      if (!isConti) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '필수 입력값을 입력해 주세요. [기계장비유형명, 순번]',
          type: 'warning', // success / info / warning / error
        });
      } else {
        let saveData = this.mstGrid.data.filter( x => {
          return x.editFlag == 'C' || x.editFlag == 'U'
        });
        this.$_.forEach(saveData, item => {
          item.regUserId = this.$store.getters.user.userId;
          item.chgUserId = this.$store.getters.user.userId;
        });
        if(saveData.length > 0) {
          window.getApp.$emit('CONFIRM', {
            title: '확인',
            message: '저장하시겠습니까?',
            type: 'info',
            // 확인 callback 함수
            confirmCallback: () => {
              this.$http.url = transactionConfig.mdm.cmd.hhmtype.save.url;
              this.$http.type = 'POST';
              this.$http.param = saveData;
              this.$http.request(() => {
                this.getCodeMst();
                window.getApp.$emit('APP_REQUEST_SUCCESS');
              });
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('ALERT', {
            title: '안내 ', /* 안내 */
            message: '변경된 데이터가 없습니다.', /* 변경된 데이터가 없습니다. */
            type: 'info', // success / info / warning / error
          });
        }
      }
    },
    removeType() {
      let selectData = this.$refs['mstTable'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '하나 이상 선택하세요.', // 하나 이상 선택하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '삭제하시겠습니까?',
          type: 'warning', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = transactionConfig.mdm.cmd.hhmtype.delete.url;
            this.$http.type = 'DELETE';
            this.$http.param = {
              data: Object.values(selectData)
            };
            this.$http.request(() => {
              // this.getCodeMst();
              window.getApp.$emit('APP_REQUEST_SUCCESS');
              
              this.$_.forEach(selectData, item => {
                this.mstGrid.data = this.$_.reject(this.mstGrid.data, item);
              })
              this.$refs['mstTable'].$refs['compo-table'].clearSelection();
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    addrowCopy() {
      let selectData = this.$refs['grid'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '대상 점검항목을 선택하세요.', // 대상 점검항목을 선택하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        this.grid.data.splice(selectData[0].sortOrder, 0, {
          editFlag: 'C',
          hazardousMachineryTypeInsId: uid(),  // 기계장비유형별 점검항목일련번호
          hhmTypeCd: this.selectedRows.code,  // 기계장비유형코드
          typeName: selectData[0].typeName,  // 구분
          inspectItem: '',  // 자율점검항목
          sortOrder: selectData[0].sortOrder + 1,  // 순번
        });
      }
    }
  }
};
</script>
